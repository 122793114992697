import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "1200px" }, attrs: { id: "margin-dialog-body" } },
    [
      _c(
        VCard,
        [
          _c(
            VToolbar,
            { attrs: { flat: "", dark: "", color: "primary" } },
            [
              _c(
                VBtn,
                {
                  attrs: { icon: "", dark: "" },
                  on: { click: _vm.closeDialog }
                },
                [_c(VIcon, [_vm._v("mdi-close")])],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", sm: "20", align: "left" } },
                [_c(VToolbarTitle, [_vm._v("Ofertas Título ")])],
                1
              ),
              _c(VCol, { attrs: { cols: "12", sm: "9", align: "right" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        [
          _c(
            VContainer,
            [
              _c(VCardTitle, { staticClass: "headline primary--text" }, [
                _vm._v("Informações do Título: ")
              ]),
              _c(
                VCol,
                { staticClass: "mt-3", attrs: { sm: "12", lg: "12" } },
                [
                  _c(
                    VCard,
                    { staticClass: "grey lighten-4" },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "1", sm: "12", lg: "2" } },
                            [
                              _c(
                                VSheet,
                                {
                                  staticClass:
                                    "v-sheet--offset ml-3 text-center",
                                  attrs: {
                                    color: "primary",
                                    elevation: "12",
                                    "max-width": "100%"
                                  }
                                },
                                [
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "ma-1 pa-1",
                                      attrs: { color: "white", size: "50" }
                                    },
                                    [_vm._v(" format_list_bulleted ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "1", sm: "12", lg: "2" } },
                            [
                              _c(VCardText, { staticClass: "pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-light grey--text"
                                  },
                                  [_vm._v(" ID do título ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-black title"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.detalhe_titulo.id_titulo) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "1", sm: "12", lg: "2" } },
                            [
                              _c(VCardText, { staticClass: "pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-light grey--text"
                                  },
                                  [_vm._v(" Título ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-black title"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.detalhe_titulo.ds_numero_titulo
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "1", sm: "12", lg: "2" } },
                            [
                              _c(VCardText, { staticClass: "pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-light grey--text"
                                  },
                                  [_vm._v(" Parcela ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-black title"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.detalhe_titulo.nu_parcela) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "1", sm: "12", lg: "2" } },
                            [
                              _c(VCardText, { staticClass: "pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-light grey--text"
                                  },
                                  [_vm._v(" Saldo Protestado ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-black title"
                                  },
                                  [
                                    _vm._v(
                                      " R$ " +
                                        _vm._s(
                                          _vm.detalhe_titulo
                                            .vl_saldo_protesto_formatado
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "1", sm: "12", lg: "2" } },
                            [
                              _c(VCardText, { staticClass: "pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-light grey--text"
                                  },
                                  [_vm._v(" Valor Título ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-black title"
                                  },
                                  [
                                    _vm._v(
                                      " R$ " +
                                        _vm._s(
                                          _vm.detalhe_titulo.vl_titulo_formatado
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "ml-1", attrs: { cols: "12" } },
                [
                  _c(
                    VCardTitle,
                    { staticClass: "headline primary--text" },
                    [_vm._v(" Ofertas Globais: ")]
                  ),
                  _c(VDataTable, {
                    staticClass: "elevation-2",
                    attrs: {
                      headers: _vm.headersGlobal,
                      items: _vm.ofertasGlobais,
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.data_inicio",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(item.data_inicio)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.data_fim",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(item.data_fim)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.desconto_porcentagem",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPorcentagem(
                                        item.desconto_porcentagem
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.juros_boleto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPorcentagemJurosBoleto(
                                        item.juros_boleto
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.juros_cartao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPorcentagemJurosCartao(
                                        item.juros_cartao
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.parcela_cartao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatParcelasCartao(
                                        item.parcela_cartao
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.parcela_boleto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatParcelasBoleto(
                                        item.parcela_boleto
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.desconto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDinheiro(item.desconto)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(VSwitch, {
                                style: {
                                  color: item.status == true ? "green" : "red"
                                },
                                attrs: {
                                  color: item.status == true ? "green" : "red",
                                  readonly: "",
                                  inset: ""
                                },
                                model: {
                                  value: item.status,
                                  callback: function($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.cartao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(VSwitch, {
                                style: {
                                  color:
                                    item.cartao == true && item.status == true
                                      ? "green"
                                      : "grey"
                                },
                                attrs: {
                                  color:
                                    item.cartao == true && item.status == true
                                      ? "green"
                                      : "grey",
                                  readonly: "",
                                  inset: "",
                                  disabled: item.status
                                },
                                model: {
                                  value: item.cartao,
                                  callback: function($$v) {
                                    _vm.$set(item, "cartao", $$v)
                                  },
                                  expression: "item.cartao"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.boleto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(VSwitch, {
                                style: {
                                  color:
                                    item.boleto == true && item.status == true
                                      ? "green"
                                      : "grey"
                                },
                                attrs: {
                                  color:
                                    item.boleto == true && item.status == true
                                      ? "green"
                                      : "grey",
                                  readonly: "",
                                  inset: "",
                                  disabled: item.status
                                },
                                model: {
                                  value: item.boleto,
                                  callback: function($$v) {
                                    _vm.$set(item, "boleto", $$v)
                                  },
                                  expression: "item.boleto"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "ml-1", attrs: { cols: "12" } },
                [
                  _c(
                    VCardTitle,
                    { staticClass: "headline primary--text" },
                    [_vm._v(" Ofertas Título: ")]
                  ),
                  _c(VDataTable, {
                    staticClass: "elevation-2",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.ofertas,
                      "item-key": "ofertas"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.action",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.status
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "mr-2 grey white--text",
                                      attrs: { elevation: "1", width: "100%" },
                                      on: {
                                        click: function($event) {
                                          return _vm.botaodesabilitarOferta(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "mr-2",
                                          style: { color: "white" },
                                          attrs: { medium: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.botaodesabilitarOferta(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("close ")]
                                      ),
                                      _vm._v(" Desabilitar ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !item.status
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "mr-2 grey white--text",
                                      attrs: {
                                        elevation: "1",
                                        width: "100%",
                                        disabled: ""
                                      }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "mr-2",
                                          style: { color: "white" },
                                          attrs: { medium: "" }
                                        },
                                        [_vm._v("mdi-information ")]
                                      ),
                                      _vm._v(" Desabilitado ")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item.data_inicio",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(item.data_inicio)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.data_fim",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(item.data_fim)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.desconto_porcentagem",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPorcentagem(
                                        item.desconto_porcentagem
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.juros_boleto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPorcentagemJurosBoleto(
                                        item.juros_boleto
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.juros_cartao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPorcentagemJurosCartao(
                                        item.juros_cartao
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.parcela_cartao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatParcelasCartao(
                                        item.parcela_cartao
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.parcela_boleto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatParcelasBoleto(
                                        item.parcela_boleto
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.desconto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDinheiro(item.desconto)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(VSwitch, {
                                style: {
                                  color: item.status == true ? "green" : "red"
                                },
                                attrs: {
                                  color: item.status == true ? "green" : "red",
                                  readonly: "",
                                  inset: ""
                                },
                                model: {
                                  value: item.status,
                                  callback: function($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.cartao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(VSwitch, {
                                style: {
                                  color:
                                    item.cartao == true && item.status == true
                                      ? "green"
                                      : "grey"
                                },
                                attrs: {
                                  color:
                                    item.cartao == true && item.status == true
                                      ? "green"
                                      : "grey",
                                  readonly: "",
                                  inset: "",
                                  disabled: item.status
                                },
                                model: {
                                  value: item.cartao,
                                  callback: function($$v) {
                                    _vm.$set(item, "cartao", $$v)
                                  },
                                  expression: "item.cartao"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.boleto",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(VSwitch, {
                                style: {
                                  color:
                                    item.boleto == true && item.status == true
                                      ? "green"
                                      : "grey"
                                },
                                attrs: {
                                  color:
                                    item.boleto == true && item.status == true
                                      ? "green"
                                      : "grey",
                                  readonly: "",
                                  inset: "",
                                  disabled: item.status
                                },
                                model: {
                                  value: item.boleto,
                                  callback: function($$v) {
                                    _vm.$set(item, "boleto", $$v)
                                  },
                                  expression: "item.boleto"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        [
          _c(
            VExpansionPanels,
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panel,
                callback: function($$v) {
                  _vm.panel = $$v
                },
                expression: "panel"
              }
            },
            [
              _c(
                VExpansionPanel,
                [
                  _c(
                    VForm,
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        VExpansionPanelHeader,
                        [
                          _c(
                            VBtn,
                            {
                              ref: "button",
                              staticClass: "ml-1",
                              staticStyle: { flex: "inherit" },
                              attrs: {
                                disabled: _vm.isActive,
                                block: "",
                                medium: "",
                                color: "green white--text"
                              },
                              on: { click: _vm.clearDialog }
                            },
                            [
                              _vm._v(" Criar nova Oferta "),
                              _c(VIcon, { staticClass: "ml-2" }, [
                                _vm._v("local_offer")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VExpansionPanelContent,
                        [
                          _vm.snackbar
                            ? _c("Snackbar", {
                                attrs: {
                                  mostrarSnackbar: _vm.snackbar,
                                  corSnackbar: _vm.snackbarColor,
                                  mensagemSnackbar: _vm.mensagemAviso
                                },
                                on: {
                                  fecharSnackbar: function($event) {
                                    _vm.snackbar = false
                                  }
                                }
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.passo1,
                                  expression: "passo1"
                                }
                              ]
                            },
                            [
                              _c(
                                VCard,
                                { staticClass: "grey lighten-4 ma-3 pa-3" },
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "ma-auto mt-3",
                                          attrs: { sm: "3", md: "5" }
                                        },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCard,
                                                {
                                                  staticClass: "pa-3 ma-3",
                                                  attrs: { shaped: "" }
                                                },
                                                [
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end align-center"
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "primary--text ma-auto pa-n3 ma-4",
                                                          attrs: { size: "100" }
                                                        },
                                                        [_vm._v(" mdi-sale ")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VCol,
                                                    { attrs: { sm: "12" } },
                                                    [
                                                      _c(
                                                        VCardText,
                                                        {
                                                          staticClass:
                                                            "display-1 primary--text font-weight-black"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Informe a porcentagem de desconto "
                                                          )
                                                        ]
                                                      ),
                                                      _c(VTextField, {
                                                        staticClass:
                                                          "display-1 font-weight-black",
                                                        attrs: {
                                                          "error-messages":
                                                            _vm.checkboxErrors,
                                                          label:
                                                            "Porcentagem de desconto",
                                                          "not-required": "",
                                                          prefix: "%",
                                                          solo: ""
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.formatDescontoPorcentagem
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.computedDescontoPorcentagem,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.computedDescontoPorcentagem = $$v
                                                          },
                                                          expression:
                                                            "\n                                                            computedDescontoPorcentagem\n                                                        "
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            VCardText,
                                            {
                                              staticClass:
                                                "title-1 grey--text font-weight-regular"
                                            },
                                            [
                                              _vm._v(
                                                " Selecione essa opção para oferecer o desconto em formato de porcentagem (%). Ao selecionar essa opção não será possivel selecionar desconto em valor fixo (R$). "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "ma-auto mt-3",
                                          attrs: { sm: "3", md: "5" }
                                        },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCard,
                                                {
                                                  staticClass: "pa-3 ma-3",
                                                  attrs: { shaped: "" }
                                                },
                                                [
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end align-center"
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "primary--text ma-auto pa-n3 ma-4",
                                                          attrs: { size: "100" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-cash-multiple "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VCol,
                                                    [
                                                      _c(
                                                        VCardText,
                                                        {
                                                          staticClass:
                                                            "display-1 primary--text font-weight-black"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Informe o Valor de Desconto "
                                                          )
                                                        ]
                                                      ),
                                                      _c(VTextField, {
                                                        staticClass:
                                                          "display-1 font-weight-black",
                                                        attrs: {
                                                          "error-messages":
                                                            _vm.checkboxErrors,
                                                          label:
                                                            "Valor do desconto",
                                                          "not-required": "",
                                                          clearable: "",
                                                          solo: "",
                                                          prefix: "R$"
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.formatDesconto
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.computedDesconto,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.computedDesconto = $$v
                                                          },
                                                          expression:
                                                            "\n                                                            computedDesconto\n                                                        "
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCardText,
                                                {
                                                  staticClass:
                                                    "title-1 grey--text font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    " Selecione essa opção para oferecer o desconto em formato de valor Fixo (R$). Ao selecionar essa opção não será possivel selecionar desconto em formato porcentagem (%). "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.passo2,
                                  expression: "passo2"
                                }
                              ]
                            },
                            [
                              _c(
                                VRow,
                                { staticClass: "ma-3 pa-3" },
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", sm: "3", md: "6" } },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticClass:
                                            "ma-3 pa-6 grey lighten-5"
                                        },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass:
                                                    "primary--text ml-5",
                                                  attrs: { size: "40" }
                                                },
                                                [_vm._v(" credit_card ")]
                                              ),
                                              _c(
                                                VCardTitle,
                                                {
                                                  staticClass: "primary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    " Desejo Oferecer pagamento via Cartão "
                                                  )
                                                ]
                                              ),
                                              _c(VSwitch, {
                                                staticClass:
                                                  "white--text ma-auto",
                                                attrs: {
                                                  solo: "",
                                                  label: "Ativar",
                                                  required: "",
                                                  disabled: ""
                                                },
                                                on: {
                                                  change: _vm.limparCartao
                                                },
                                                model: {
                                                  value:
                                                    _vm.formulario_oferta
                                                      .cartao,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formulario_oferta,
                                                      "cartao",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                    formulario_oferta.cartao\n                                                "
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formulario_oferta
                                                      .cartao,
                                                  expression:
                                                    "\n                                                formulario_oferta.cartao\n                                            "
                                                }
                                              ]
                                            },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  solo: "",
                                                  prefix: "x",
                                                  label: "Máximo de Parcelas",
                                                  type: "number",
                                                  disabled: "",
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.formulario_oferta
                                                      .parcela_cartao,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formulario_oferta,
                                                      "parcela_cartao",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                    formulario_oferta.parcela_cartao\n                                                "
                                                }
                                              }),
                                              _c(VTextField, {
                                                attrs: {
                                                  solo: "",
                                                  label: "Juros por parcela",
                                                  prefix: "%",
                                                  disabled: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.formulario_oferta
                                                      .juros_cartao,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formulario_oferta,
                                                      "juros_cartao",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                    formulario_oferta.juros_cartao\n                                                "
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass:
                                            "title-1 grey--text font-weight-regular"
                                        },
                                        [
                                          _vm._v(
                                            " Ao ativar essa opção estará habilitando a opção de pagamento via Cartão de Crédito, podendo configurar a quantidade máxima de parcelas e juros. "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", sm: "3", md: "6" } },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticClass:
                                            "ma-3 pa-6 grey lighten-5"
                                        },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass:
                                                    "primary--text ml-5",
                                                  attrs: { size: "40" }
                                                },
                                                [_vm._v(" horizontal_split ")]
                                              ),
                                              _c(
                                                VCardTitle,
                                                {
                                                  staticClass: "primary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    " Desejo Oferecer pagamento via Boleto "
                                                  )
                                                ]
                                              ),
                                              _c(VSwitch, {
                                                staticClass:
                                                  "white--text ma-auto",
                                                attrs: {
                                                  solo: "",
                                                  label: "Ativar",
                                                  required: ""
                                                },
                                                on: {
                                                  change: _vm.limparBoleto
                                                },
                                                model: {
                                                  value:
                                                    _vm.formulario_oferta
                                                      .boleto,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formulario_oferta,
                                                      "boleto",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                    formulario_oferta.boleto\n                                                "
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formulario_oferta
                                                      .boleto,
                                                  expression:
                                                    "\n                                                formulario_oferta.boleto\n                                            "
                                                }
                                              ]
                                            },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  solo: "",
                                                  prefix: "x",
                                                  disabled: !_vm
                                                    .formulario_oferta.boleto,
                                                  label: "Máximo de Parcelas",
                                                  type: "number",
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.formulario_oferta
                                                      .parcela_boleto,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formulario_oferta,
                                                      "parcela_boleto",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                    formulario_oferta.parcela_boleto\n                                                "
                                                }
                                              }),
                                              _c(VTextField, {
                                                attrs: {
                                                  solo: "",
                                                  prefix: "%",
                                                  disabled: !_vm
                                                    .formulario_oferta.boleto,
                                                  label: "Juros por boleto",
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.formulario_oferta
                                                      .juros_boleto,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formulario_oferta,
                                                      "juros_boleto",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                    formulario_oferta.juros_boleto\n                                                "
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass:
                                            "title-1 grey--text font-weight-regular"
                                        },
                                        [
                                          _vm._v(
                                            " Ao ativar essa opção estará habilitando a opção de pagamento via Boleto Bancário, podendo configurar a quantidade máxima de parcelas e juros. "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.passo3,
                                  expression: "passo3"
                                }
                              ]
                            },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticClass:
                                    "grey--text font-weight-black justify-center title-2"
                                },
                                [
                                  _c(VIcon, [
                                    _vm._v(" keyboard_arrow_down ")
                                  ]),
                                  _vm._v(" Passo 3: Definir Prazos da Oferta: ")
                                ],
                                1
                              ),
                              _c(
                                VCard,
                                { staticClass: "ma-6 pa-3 white" },
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "mx-auto my-auto",
                                          attrs: {
                                            cols: "8",
                                            sm: "12",
                                            md: "1"
                                          }
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              staticClass:
                                                "primary--text d-block",
                                              attrs: { size: "100" }
                                            },
                                            [_vm._v(" date_range ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "mx-auto my-auto",
                                          attrs: {
                                            cols: "8",
                                            sm: "12",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            {
                                              staticClass:
                                                "primary--text display-1 font-weight-black d-block"
                                            },
                                            [
                                              _vm._v(
                                                " Início e Encerramento da Oferta "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "8",
                                            sm: "12",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    VMenu,
                                                    {
                                                      ref: "menu1",
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "return-value":
                                                          _vm.oferta
                                                            .data_inicio,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "290px"
                                                      },
                                                      on: {
                                                        "update:returnValue": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.oferta,
                                                            "data_inicio",
                                                            $event
                                                          )
                                                        },
                                                        "update:return-value": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.oferta,
                                                            "data_inicio",
                                                            $event
                                                          )
                                                        }
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                VTextField,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Defina o Início da Oferta",
                                                                        "prepend-icon":
                                                                          "mdi-calendar",
                                                                        readonly:
                                                                          "",
                                                                        clearable:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.computedDateFormattedInicio,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.computedDateFormattedInicio = $$v
                                                                        },
                                                                        expression:
                                                                          "\n                                                                computedDateFormattedInicio\n                                                            "
                                                                      }
                                                                    },
                                                                    "v-text-field",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ]),
                                                      model: {
                                                        value: _vm.menu1,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.menu1 = $$v
                                                        },
                                                        expression: "menu1"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        VDatePicker,
                                                        {
                                                          attrs: {
                                                            "no-title": "",
                                                            scrollable: "",
                                                            locale: "pt-br"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.oferta
                                                                .data_inicio,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.oferta,
                                                                "data_inicio",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                                                            oferta.data_inicio\n                                                        "
                                                          }
                                                        },
                                                        [
                                                          _c(VSpacer),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.menu1 = false
                                                                }
                                                              }
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$refs.menu1.save(
                                                                    _vm.oferta
                                                                      .data_inicio
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v(" OK ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    VMenu,
                                                    {
                                                      ref: "menu2",
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "return-value":
                                                          _vm.oferta.data_fim,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "290px"
                                                      },
                                                      on: {
                                                        "update:returnValue": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.oferta,
                                                            "data_fim",
                                                            $event
                                                          )
                                                        },
                                                        "update:return-value": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.oferta,
                                                            "data_fim",
                                                            $event
                                                          )
                                                        }
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                VTextField,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Defina o Encerramento",
                                                                        "prepend-icon":
                                                                          "mdi-calendar",
                                                                        readonly:
                                                                          "",
                                                                        "persistent-hint":
                                                                          "",
                                                                        clearable:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.computedDateFormattedFim,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.computedDateFormattedFim = $$v
                                                                        },
                                                                        expression:
                                                                          "\n                                                                computedDateFormattedFim\n                                                            "
                                                                      }
                                                                    },
                                                                    "v-text-field",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ]),
                                                      model: {
                                                        value: _vm.menu2,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.menu2 = $$v
                                                        },
                                                        expression: "menu2"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        VDatePicker,
                                                        {
                                                          attrs: {
                                                            "no-title": "",
                                                            scrollable: "",
                                                            locale: "pt-br"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.oferta
                                                                .data_fim,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.oferta,
                                                                "data_fim",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                                                            oferta.data_fim\n                                                        "
                                                          }
                                                        },
                                                        [
                                                          _c(VSpacer),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.menu2 = false
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Cancelar "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$refs.menu2.save(
                                                                    _vm.oferta
                                                                      .data_fim
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v(" OK ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(VCol, {
                                        staticClass: "mx-auto my-auto",
                                        attrs: { cols: "12", sm: "3", md: "3" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VCardTitle,
                                {
                                  staticClass:
                                    "grey--text font-weight-black justify-center title-2"
                                },
                                [
                                  _c(VIcon, [
                                    _vm._v(" keyboard_arrow_down ")
                                  ]),
                                  _vm._v(" Passo 4: Ative a Renegociação: ")
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  staticClass: "mx-auto my-auto",
                                  attrs: { cols: "12", sm: "3", md: "12" }
                                },
                                [
                                  _c(
                                    VCard,
                                    { staticClass: "ma-3 pa-3 green" },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "mx-auto my-auto" },
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { sm: "3", md: "8" } },
                                            [
                                              _c(
                                                VCardTitle,
                                                {
                                                  staticClass:
                                                    "title-2 font-weight-black white--text"
                                                },
                                                [
                                                  _vm._v(
                                                    " Tudo pronto? Ative a oferta"
                                                  ),
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "white--text",
                                                      attrs: { size: "40" }
                                                    },
                                                    [_vm._v(" double_arrow ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            VCol,
                                            {
                                              staticClass: "mx-auto my-auto",
                                              attrs: { sm: "3", md: "4" }
                                            },
                                            [
                                              _c(
                                                VCard,
                                                { staticClass: "ma-2 pa-2" },
                                                [
                                                  _c(VSwitch, {
                                                    staticClass:
                                                      "mx-auto my-auto",
                                                    attrs: {
                                                      color: "success",
                                                      inset: "",
                                                      label:
                                                        "Desativar/Ativar Oferta",
                                                      required: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formulario_oferta
                                                          .status,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.formulario_oferta,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                                                        formulario_oferta.status\n                                                    "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(VContainer, [
                            _c(
                              "form",
                              {
                                attrs: { id: "ofertaForm" },
                                on: { submit: _vm.salvarOferta }
                              },
                              [
                                _c(
                                  VCol,
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "ml-6",
                                        attrs: {
                                          dense: "",
                                          color: "green white--text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.salvarOferta()
                                          }
                                        }
                                      },
                                      [_vm._v(" Salvar e Enviar ")]
                                    ),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "ml-1",
                                        attrs: {
                                          color: "grey darken-1",
                                          text: ""
                                        },
                                        on: { click: _vm.closeDialog }
                                      },
                                      [_vm._v(" Cancelar ")]
                                    ),
                                    _c(VSpacer)
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: {
            persistent: "",
            transition: "dialog-bottom-transition",
            width: "450px"
          },
          model: {
            value: _vm.dialogLoading,
            callback: function($$v) {
              _vm.dialogLoading = $$v
            },
            expression: "dialogLoading"
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center", attrs: { width: "420px" } },
            [
              _c(
                VCard,
                [
                  _c(
                    VProgressCircular,
                    {
                      staticClass: "ma-12",
                      attrs: {
                        indeterminate: "",
                        size: "150",
                        color: "primary"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.mensagemLoading) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }